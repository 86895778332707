import { flushBuffer, makeLogHandler } from './log.handler';
import { getLogs } from './log.sender';
import { LogLevel, LoggerConfig, LoggerService } from './logger.interface';
import { refConsole, serviceWrapper, startInterceptingConsole } from './utils';

class Logger {
  private config: LoggerConfig = {
    production: false,
    appName: '',
    logsUrl: '',
  };

  private isSentryInitialized = false;
  private _merchantName = '';

  set version(version: string) {
    this.config.version = version;
  }

  set merchantName(merchantName: string) {
    this._merchantName = merchantName;
    this.setSentryTag('merchantName', merchantName);
  }

  set fullStoryUrl(url: string) {
    this.setSentryTag('fullStoryUrl', url);
  }

  set sessionId(id: string) {
    this.config.sessionId = id;
    this.setSentryTag('sessionId', id);
    flushBuffer(this.getConfig());
  }

  set origin(origin: string) {
    this.config.origin = origin;
    this.setSentryTag('origin', origin);
  }

  set browserSessionId(id: string) {
    this.config.browserSessionId = id;
    this.setSentryTag('browserSessionId', id);
    flushBuffer(this.getConfig());
  }

  set deviceSessionId(id: string) {
    this.config.deviceSessionId = id;
    this.setSentryTag('deviceSessionId', id);
  }

  set correlationId(id: string) {
    this.config.correlationId = id;
    this.setSentryTag('correlationId', id);
  }

  set linkId(id: string) {
    this.config.linkId = id;
    this.setSentryTag('linkId', id);
  }

  protected isInitialized = false;

  constructor(config?: LoggerConfig) {
    if (config) {
      this.init(config);
    }
  }

  private setSentryTag(tagName: string, value: string) {
    this.setSentryTags({ [tagName]: value });
  }

  private setSentryTags(tags: Record<string, string>) {
    if (this.isSentryInitialized) {
      import('./sentry.manager').then(({ setSentryTags }) =>
        setSentryTags(tags)
      );
    }
  }

  private initSentry() {
    if (
      this.config?.sentryConfig?.dsn &&
      !/headless/.test(navigator.userAgent.toLowerCase())
    ) {
      import('./sentry.manager').then(({ initSentry }) => {
        if (this.config.sentryConfig) {
          initSentry(this.config.sentryConfig);
          this.isSentryInitialized = true;
        }
      });
    }
  }

  private getConfig = () => ({
    config: this.config,
    merchantName: this._merchantName,
    isInitialized: this.isInitialized,
  });

  init(config: LoggerConfig) {
    this.config = config;

    this.initSentry();

    this.isInitialized = !!config;
    startInterceptingConsole(config.appName);
  }

  log = makeLogHandler(LogLevel.LOG, this.getConfig);
  info = makeLogHandler(LogLevel.INFO, this.getConfig);
  warn = makeLogHandler(LogLevel.WARN, this.getConfig);
  error = makeLogHandler(LogLevel.ERROR, this.getConfig);

  group = (args: unknown[]) => {
    refConsole.group('[ChargeAfter]', args);
  };

  groupCollapsed = (...args: unknown[]) => {
    refConsole.groupCollapsed('[ChargeAfter]', ...args);
  };

  groupEnd = () => {
    refConsole.groupEnd();
  };

  service = (serviceName: string): LoggerService => {
    let version = '-1';
    const getServiceData = () => {
      return {
        version,
        service: serviceName,
      };
    };

    return {
      set version(_version: string) {
        version = _version;
      },
      log: serviceWrapper(getServiceData, this.log),
      info: serviceWrapper(getServiceData, this.info),
      warn: serviceWrapper(getServiceData, this.warn),
      error: serviceWrapper(getServiceData, this.error),
    };
  };

  flush = () => {
    flushBuffer(this.getConfig(), true);
  };

  getLogs = () => getLogs();
  getServerUrl = () => this.config.logsUrl;
}

const logger = new Logger();

export { logger, Logger };

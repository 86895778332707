import { MerchantCheckoutOpt } from '@chargeafter/payment-types';
import { MerchantOpt } from '@chargeafter/types-sdk';

import { logger } from '../../../logger';
import { modalPresent } from '../../../manager';
import { OpenPaymentsModal } from '../../../manager/modal-manager/manager.model';
import { browserSessionId } from '../../../utils/browserSessionId';
import {
  isMerchantOptValid,
  roundDecimalForCart,
} from '../../../validations/validations';
import { store } from '../../store';

const filename = 'paymentsPresent';

export const paymentsPresent = async (
  type: 'apply' | 'checkout',
  opt: MerchantOpt = {} as MerchantOpt
) => {
  const ttlStartTime = Date.now();

  const shouldRemoveApplicationId =
    opt &&
    type === 'apply' &&
    'applicationId' in opt &&
    opt.applicationId !== undefined &&
    opt.applicationId !== null;

  if (typeof opt !== 'object' || Array.isArray(opt)) {
    logger.error('invalid opt received, opt must be an object', {
      function: 'paymentsPresent',
      filename,
      printOriginalToConsole: true,
      opt,
    });
    return; // FIXME: should return error object;
  }

  if (shouldRemoveApplicationId) {
    logger.warn('applicationId cannot be sent in apply', {
      filename,
      function: 'applyPresent',
      printOriginalToConsole: true,
      isConsoleOnly: true,
    });
  }

  if (opt && type === 'checkout' && !opt.isCheckout) {
    opt.isCheckout = true;
  }

  if (
    type === 'apply' &&
    opt &&
    (opt.isCheckout || typeof opt.isCheckout === 'undefined')
  ) {
    (opt.isCheckout as boolean) = false;
  }

  if (opt.consumerDetails?.mobilePhoneNumber) {
    opt.consumerDetails.mobilePhoneNumber =
      opt.consumerDetails.mobilePhoneNumber.toString().replace(/\D/g, '');
  }

  if (!isMerchantOptValid(opt as MerchantCheckoutOpt)) {
    return void 0;
  } // FIXME: should return error object;

  if (type === 'checkout') {
    roundDecimalForCart((opt as MerchantCheckoutOpt).cartDetails);
  }

  const { onComplete } = store.config;

  const {
    callback,
    onDataUpdate,
    onConfirm,
    onApprovalStatusChange,
    onModalOpen,
    onApplicationCreated,
    ...restOpt
  } = opt as MerchantCheckoutOpt;

  const isUpdateCallBackProvided = !!onDataUpdate;
  const isCallBackProvided = !!callback || !!onComplete;

  const merchantOpt = {
    ...restOpt,
    isUpdateCallBackProvided,
    isCallBackProvided,
    ...(shouldRemoveApplicationId && {
      applicationId: undefined,
    }),
  };

  const payload: OpenPaymentsModal = {
    sendBrowserSessionStartedEvent:
      browserSessionId.sendBrowserSessionStartedEvent,
    browserSessionIdTimestamp: browserSessionId.timestamp,
    type: 'payments',
    paymentsData: { opt: merchantOpt },
    configurationSessionId: merchantOpt.configurationSessionId,
    ttlStartTime,
  };

  const data =
    (await modalPresent({
      config: store.config,
      payload,
      isCheckout: type === 'checkout',
      merchantActions: {
        callback,
        onDataUpdate,
        onConfirm,
        onApprovalStatusChange,
        onModalOpen,
        onComplete,
        onApplicationCreated,
        onApplicationCreatedGlobal: store.config.onApplicationCreated,
      },
    })) || {};

  browserSessionId.sendBrowserSessionStartedEvent = false;

  return data;
};

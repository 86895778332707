import { ModalConfig, SDKConfig } from '@chargeafter/types-sdk';

import { logger } from '../../logger';
import { browserSessionId } from '../../utils/browserSessionId';
import { refWindow } from '../../utils/windowRef';
import { initWidgets } from '../initWidgets/initWidgets';
import { store } from '../store';

const filename = 'sdkInit';

export const sdkInit = async (merchantConfig: SDKConfig) => {
  if (!store.isInitialized) {
    let onFinishInit: () => void;

    store.sdkInitializedPromise = new Promise<void>((resolve) => {
      onFinishInit = resolve;
    });

    logger.browserSessionId = browserSessionId.get;
    logger.log('initializing SDK', {
      function: 'init',
      filename,
      config: store.config,
    });

    if (
      !merchantConfig ||
      typeof merchantConfig.apiKey !== 'string' ||
      !merchantConfig.apiKey
    ) {
      return logger.error(
        !merchantConfig
          ? 'config was not provided'
          : 'invalid api key received',
        {
          function: 'init',
          filename,
          printOriginalToConsole: true,
          isSeverityWarn: true,
        }
      );
    }

    const tConfig: ModalConfig = {
      ...merchantConfig,
      browserSessionId: browserSessionId.get,
      origin: refWindow.location?.origin,
    };

    store.config = tConfig;

    store.isInitialized = true;

    initWidgets(undefined, store.config.preferences?.language)
      .then(() => {
        onFinishInit?.();
      })
      .catch((error) => {
        logger.error('SDK failed to init widget with error', {
          function: 'init',
          filename,
          error,
          errorMessage: (error as Error).message,
        });
        onFinishInit?.();
      });

    store.config.onLoaded?.();
  } else {
    logger.warn('initializing SDK while already initialized', {
      function: 'init',
      filename,
      config: store.config,
    });
  }
};
